import Paginator from '../_paginator';

let getCashFlowDashboard = (config) => {
  return new Paginator({
    url: 'dashboard-cashflow',
    config: config,
  });
};

export default {
    getCashFlowDashboard
  };
  