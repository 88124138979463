import http from '../api';
import Paginator from '../_paginator';

let getAll = (config) => {
  return new Paginator({
    url: 'project-type',
    config: config,
  });
};

let getOne = (data) => {
  return http.get(`project-type/${data.params.id}`);
};

let store = (data) => {
  return http.post('project-type', data);
};

let update = async (data) => {
  const response = await http.put(`project-type?id=${data.id}`, data);
  return response;
};

let deletee = async (data) => {
  const response = await http.delete(`project-type?id=${data}`);
  return response;
};

let activate = async (data) => {
  const response = await http.put(`project-type-reactivate?id=${data.id}`);
  return response;
};

export default {
  getAll,
  getOne,
  store,
  update,
  activate,
  deletee,
};
