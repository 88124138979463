import http from '../api';
import Paginator from '../_paginator';

let getAll = (config) => {
  return new Paginator({
    url: 'taxes',
    config: config,
  });
};
let getAllInactive = (data) => {
  return http.get(`taxes/?workspace_id=${data.workspace_id}`, data);
};

let store = (data) => {
  return http.post('taxes', data);
};
let get = (data) => {
  return http.get(`tax?workspace_id=${data.workspace_id}`, data);
};
let update = (data) => {
  return http.put(`taxes/${data.workspace_id}`, data);
};
let activateTaxes = (data) => {
  return http.put(`activate-taxes/${data.id}`, data);
};

let deletee = (data) => {
  return http.delete(`taxes/?id=${data.id}`, data);
};

export default {
  getAll,
  store,
  get,
  update,
  activateTaxes,
  deletee,
  getAllInactive,
};
