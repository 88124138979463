import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import auth from './auth';
import user from './user';
import workspaces from './workspaces';
import taxes from './taxes';
import projectCosts from './projectCosts';
import supplier from './supplier';
import depreciableAssets from './depreciableAssets';
import costsCategory from './costsCategory';
import projects from './projects';
import revenue from './revenue';
import expense from './expense';
import tasks from './tasks';
import taskTitles from './taskTitles';
import histories from './histories';
import cashFlow from './cashFlow';
import projectType from './projectType';




Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    user,
    tasks,
    workspaces,
    taxes,
    projectCosts,
    supplier,
    depreciableAssets,
    costsCategory,
    projects,
    revenue,
    expense,
    taskTitles,
    histories,
    cashFlow,
    projectType
  },
  strict: process.env.DEV,
});
