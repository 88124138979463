import { userDao } from '@/service';

export default {
  state: {},
  mutations: {
   
  },
  actions: {
    // User
    async getAllUsers(_, payload) {
      const config = {
        params: {
          perPage: payload.perPage,
          page: payload.page,
          inactive: payload.inactive,
          workspace_id: payload.workspace_id,
        },
      };
      return new Promise((resolve, reject) => {
        userDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getAllUsersNotVinculated(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .getAllUsersNotVinculated(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async createAuthUser(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .storeAuth(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async saveEmployee(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .storeEmployee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getUserDashboard(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .dashboard(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getDashboard(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .generalDashboard(payload)
          .then(async (resp) => {
            resolve(resp.data);
            console.log(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async saveUser(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async saveUserPermission(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .storeUserPermision(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getUser(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .get(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateUser(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async removeUser(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .deletee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async activeUser(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .activateUser(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getInactiveUsers(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .getAll({ active: false, ...payload })
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getUserMember(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .userMember(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getExportFile(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .getExportFile(payload)
          .then(async (resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async downloadFile(_, payload) {
      return new Promise((resolve, reject) => {
        userDao
          .downloadFile(payload)
          .then(async (resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // Users
  },
  getters: {
  },
};
