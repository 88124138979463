import { historiesDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {

    async getAllCalculations(_, payload) {
          const config = {
            params: {
              perPage: payload.perPage,
              page: payload.page,
              workspace_id: payload.workspace_id,
              month: payload.month,
              year: payload.year,
            },
          };
          return new Promise((resolve, reject) => {
            historiesDao
              .getAllCalculations(config)
              .getPage()
              .then(async (resp) => {
                resolve(resp.data);
              })
              .catch((err) => {
                reject(err);
              });
          });
        },
    
    async saveHistory(_, payload) {
      return new Promise((resolve, reject) => {
        historiesDao
          .saveHistory(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  getters: {},
};
