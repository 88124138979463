import taskTitlesDao from '@/service/taskTitles';

export default {
  state: {},
  mutations: {},
  actions: {
    async getAllTaskTitles(_, payload) {
      const config = {
        params: {
          workspace_id: payload.workspace_id,
          perPage: payload.perPage,
          inactive: payload.inactive,
          page: payload.page,
        },
      };
      return new Promise((resolve, reject) => {
        taskTitlesDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getTaskName(_, payload) {
      return new Promise((resolve, reject) => {
        taskTitlesDao
          .getTaskName(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async saveTaskTitle(_, payload) {
      return new Promise((resolve, reject) => {
        taskTitlesDao
          .save(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    async updateTaskTitle(_, payload) {
      return new Promise((resolve, reject) => {
        taskTitlesDao
          .modify(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    async deleteTaskTitle(_, payload) {
      return new Promise((resolve, reject) => {
        taskTitlesDao
          .destroy(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },

  getters: {},
};
