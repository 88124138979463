import { cashFlowDao} from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    async getCashFlowDashboard(_, payload) {
      const config = {
        params: {
          perPage: 1000,
          workspace_id: payload.workspace_id,
          start_date: payload.start_date,
          end_date: payload.end_date,
          project_id: payload.project_id ?  payload.project_id  : null
        },
      };
      return new Promise((resolve, reject) => {
        cashFlowDao
          .getCashFlowDashboard(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {},
};
