import http from '../api';
// import Paginator from '../_paginator';

let getAll = async (config) => {
  const { workspace_id, archived, page, perPage, start_date, end_date, project_id, status } = config;

  const response = await http.get(`revenues/?workspace_id=${workspace_id}&archived=${archived}&page=${page}&perPage=${perPage}&start_date=${start_date}&end_date=${end_date}&project_id=${project_id}&status=${status}`);
  return response;
};

let getOne = (data) => {
  return http.get(`revenues/${data.id}`);
};

let getRevenues = async (data) => {
   const response = await http.get(`revenues/?workspace_id=${data.workspace_id}&project_id=${data.project_id}&status=${data.status}&archived=${data.archived}&page=${data.page}&perPage=${data.perPage}`)
  return response
}

let store = (data) => {
  return http.post('revenues', data);
};

let update = async (data) => {
  const response = await http.put(`revenues/${data.id}`, data);
  return response;
};

let updateInstallment = async (data) => {
  const response = await http.put(`revenueInstallment/${data.id}`, data);
  return response;
};

let destroyy = async (data) => {
  const response = await http.delete(`revenues/${data}`);
  return response;
};

let activate = async (data) => {
  const response = await http.put(`revenues-reactive?id=${data.id}`);
  return response;
};

export default {
  getAll,
  getOne,
  getRevenues,
  store,
  update,
  activate,
  destroyy,
  updateInstallment
};
