import http from '../api';
import Paginator from '../_paginator';

let getAll = (config) => {
  return new Paginator({
    url: 'task-titles',
    config: config,
  });
};

let getTaskName = (data) => {
  return http.get(`task-titles/${data}`);
};

let save = (data) => {
  return http.post('task-titles', data);
};

let modify = (data) => {
  return http.put(`task-titles/${data.id}`, data);
};

let destroy = (data) => {
  return http.delete(`task-titles/${data.id}`);
};

export default {
  save,
  modify,
  getAll,
  getTaskName,
  destroy,
};
