import { projectTypeDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    async getAllProjectType(_, payload) {
      const config = {
        params: {
          perPage: payload.perPage,
          page: payload.page,
          inactive: payload.inactive,
          workspace_id: payload.workspace_id,
        },
      };
      return new Promise((resolve, reject) => {
        projectTypeDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getProjectType(_, payload) {
      const config = {
        params: {
          id: payload.id,
        },
      };
      return new Promise((resolve, reject) => {
        projectTypeDao
          .getOne(config)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async saveProjectType(_, payload) {
      return new Promise((resolve, reject) => {
        projectTypeDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateProjectType(_, payload) {
      const config = {
        name: payload.name,
        slug: payload.slug,
        id: payload.id,
      };
      return new Promise((resolve, reject) => {
        projectTypeDao
          .update(config)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async removeProjectType(_, payload) {
      return new Promise((resolve, reject) => {
        projectTypeDao
          .deletee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async activeProjectType(_, payload) {
      return new Promise((resolve, reject) => {
        projectTypeDao
          .activate(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  getters: {},
};
