import http from '../api';
import Paginator from '../_paginator';

let getAll = (config) => {
  return new Paginator({
    url: 'goods',
    config: config,
  });
};
let store = (data) => {
  return http.post('goods', data);
};
let get = (data) => {
  return http.get(`goods/${data.id}`, data);
};
let update = (data) => {
  return http.put(`goods/${data.id}`, data);
};
let activateAssets = async (data) => {
  const response = await http.put(`reactive?id=${data.id}`, data);
  return response;
};

let saveGoodsDepreciation = (data) => {
  return http.post('goods-history', data);
};

let deletee = async (data) => {
  const response = await http.delete(`goods/${data}`);
  return response;
};

export default {
  getAll,
  store,
  get,
  update,
  activateAssets,
  deletee,
  saveGoodsDepreciation
};
