import http from '../api';
import Paginator from '../_paginator';

let getAllCalculations = (config) => {
  return new Paginator({
    url: 'finacial-history',
    config: config,
  });
};

let getOne = (data) => {
  return http.get(`finacial-history/${data.id}`);
};

let saveHistory = (data) => {
  return http.post('finacial-history', data);
};


export default {
  getAllCalculations,
  getOne,
  saveHistory,
};
